const BASE_SIGN_IN_URL = '/users/sign_in';
const BASE_SIGN_OUT_URL = '/users/sign_out';
const BASE_SIGN_UP_URL = '/users/sign_up';

export const redirectToSignInPath = (redirectUrl) => {
  const signInUrl = redirectUrl
    ? `${BASE_SIGN_IN_URL}?redirect_url=${encodeURIComponent(redirectUrl)}`
    : BASE_SIGN_IN_URL;
  document.location.href = signInUrl;
};

export const signOutPath = (redirectUrl) =>
  redirectUrl
    ? `${BASE_SIGN_OUT_URL}?redirect_url=${encodeURIComponent(redirectUrl)}`
    : BASE_SIGN_OUT_URL;

export const csrfToken = () => document.querySelector('meta[name="csrf-token"]')?.content;

/**
 * Generate the sign up path with redirect url
 * @param {string} redirectUrl
 */
export const redirectToSignUpPath = (redirectUrl) => {
  const signUpUrl = redirectUrl
    ? `${BASE_SIGN_UP_URL}?redirect_url=${encodeURIComponent(redirectUrl)}`
    : BASE_SIGN_UP_URL;

  document.location.href = signUpUrl;
};
